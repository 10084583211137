import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { generateEndpoint } from 'ssotool-core/utils';
import { ConfigService } from 'ssotool-shared/services/config';

import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import {
  LibraryImportFormModel,
  LibraryImportModel,
} from '../store/library-import.model';

@Injectable()
export class LibraryImportAPIService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  get(importId: string): Observable<any> {
    return this.http
      .get<any>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.libraryImport.getImportLibraryInfo,
          importId,
        ),
      )
      .pipe(
        map((response) => this.mapImportLibraryToFrontend(response)),
        catchError((error) => throwError(error)),
      );
  }

  getList(): Observable<string[]> {
    return this.http
      .get<string[]>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.libraryImport.getImportLibraryList,
        ),
      )
      .pipe(catchError((error) => throwError(error)));
  }

  downloadLibrary(importId: string) {
    return this.http
      .get(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.libraryImport.getDownloadSignedUrl,
          importId,
        ),
      )
      .pipe(
        mergeMap((downloadSignedUrl: any) =>
          // might be broken, add responseType: 'blob' to the get request
          this.http.get(downloadSignedUrl.signedUrl).pipe(
            map((response: any) => {
              const blob = new Blob([response], {
                type: 'applications/octet-stream',
              });
              const resUrl = window.URL.createObjectURL(blob);
              const anchor = document.createElement('a');
              anchor.download = downloadSignedUrl.filename;
              anchor.href = resUrl;
              anchor.click();
              return response;
            }),
          ),
        ),
        catchError((error) => throwError(error)),
      );
  }

  importLibrary(importLibrary: LibraryImportFormModel) {
    return this.http
      .post(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.libraryImport.importLibrary,
        ),
        {
          description: importLibrary.description,
        },
      )
      .pipe(
        mergeMap((uploadResponse: any) =>
          this.http
            .get(
              generateEndpoint(
                this.config.api.baseUrl,
                this.config.api.endpoints.libraryImport.getImportSignedUrl,
                uploadResponse.importId,
                importLibrary.file.name,
              ),
            )
            .pipe(
              mergeMap((uploadSignedUrl: any) =>
                this.http
                  .put(uploadSignedUrl.signedUrl, importLibrary.file)
                  .pipe(
                    map(() => this.mapImportLibraryToFrontend(uploadResponse)),
                  ),
              ),
            ),
        ),
        catchError((error) => throwError(error)),
      );
  }

  downloadLibraryLogs(importId: string) {
    return this.http
      .get(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.libraryImport.getLogDownloadSignedUrl,
          importId,
        ),
      )
      .pipe(
        mergeMap((logDownloadSignedUrl: any) =>
          // might be broken, add responseType: 'blob' to the get request
          this.http.get(logDownloadSignedUrl.signedUrl).pipe(
            map((response: any) => {
              const blob = new Blob([response], {
                type: 'applications/octet-stream',
              });
              const resUrl = window.URL.createObjectURL(blob);
              const anchor = document.createElement('a');
              anchor.download = logDownloadSignedUrl.filename;
              anchor.href = resUrl;
              anchor.click();
              return response;
            }),
          ),
        ),
        catchError((error) => throwError(error)),
      );
  }

  mapImportLibraryToFrontend(data: any): LibraryImportModel {
    return {
      importId: data.importId,
      owner: data.owner,
      description: data.description,
      date: this.formatDate(data?.createdAt),
      status: data.status,
    };
  }

  formatDate(dateString: string) {
    if (dateString) {
      return formatDate(dateString, 'yyyy/MM/dd HH:mm', this.locale);
    }
    return;
  }
}
