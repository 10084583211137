import { combineLatest, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  startWith,
  switchMap,
  takeUntil,
  takeWhile,
} from 'rxjs/operators';
import { ClientFacadeService, ProfileFacadeService } from 'ssotool-app/+client';
import {
  Coerce,
  generateUuid,
  SSOToolRoutePathService,
  UserStateManagerService,
} from 'ssotool-shared';

import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthState } from '@okta/okta-auth-js';

import { AnnouncementFacadeService } from './+announcement/stores/announcement.facade.service';
import { CampaignExportFacadeService } from './+campaign/store/export';
import { CampaignImportFacadeService } from './+campaign/store/import/campaign-import.facade.service';
import { InputConverterService } from './+data-management/components/tools-drawer/input-converter.service';
import { DataImportFacadeService } from './+data-management/stores';
import { BinDataFacadeService } from './+entities/stores/bindata/bindata-facade.service';
import {
  CompareFacadeService,
  ResultExportFacadeService,
  ResultFacadeService,
  RoadmapFacadeService,
  RoadmapImportFacadeService,
  RoadmapVisualizationFacadeService,
} from './+roadmap/stores';
import { RoadmapVariationFacadeService } from './+roadmap/stores/roadmap-variation';
import { FEATURES } from './app-routing.module';
import { SsoDrawerLink } from './app.model';
import {
  BASELINE_INDICATOR_COMPUTATION_HOOK,
  CAMPAIGN_EXPORT,
  CAMPAIGN_IMPORT,
  CAMPAIGN_IMPORT_LOG_DETAILS,
  CLIENT_CASCADE_UPDATE,
  CLIENT_DUPLICATE_UPDATE,
  CLIENT_IMPORT,
  CLIENT_IMPORT_LOG_DETAILS,
  COMPARE_HOOK,
  COMPUTATION_HOOK,
  GANTT_CUSTOM_ICONS_FN,
  GANTT_ICON_PATH,
  INPUT_CONVERTER,
  MULTIPLE_COMPUTATION_HOOK,
  PROFILE_MODIFY,
  PROJECT_NAME,
  REGENERATE_XLS,
  RESULT_EXPORT_TRIGGER,
  ROADMAP_CASCADE_UPDATE,
  ROADMAP_CUSTOM_ICON_PATH,
  ROADMAP_CUSTOM_ICONS_FN,
  ROADMAP_IMPORT,
  ROADMAP_IMPORT_LOG_DETAILS,
  ROADMAP_VISUALIZATION_NOTIF,
  SANDBOX_CREATED_SUBJECT,
  TABLE_VIEW_CUSTOM_ICONS_FN,
  TABLE_VIEW_ICON_PATH,
  UPDATE_AFFECTED_ROADMAPS,
} from './app.references';
import {
  Breadcrumb,
  Feature,
  FeatureMetadata,
  RouterStateUrl,
} from './core/config';
import { BaseComponent } from './shared/component/base';
import { routerAnimation } from './shared/helpers/animations';
import { AuthService } from './shared/services/auth/auth.service';
import { ConfigService } from './shared/services/config';
import { I18NService } from './shared/services/i18n.service';
import { WebSocketService } from './shared/services/websocket';
import { PageStore } from './shared/stores';
import { RouterStore } from './shared/stores/router';

@UntilDestroy()
@Component({
  selector: 'sso-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  accountConfigTooltip = {
    trigger: 'click',
    followCursor: false,
    placement: 'bottom-end',
    interactive: true,
    animation: 'perspective-extreme',
  };
  currentAuthJwtToken: string;
  logoSrc = '/assets/images/engie_impact.jpg';
  isSideNavOpened = true;
  projectName: string = PROJECT_NAME;
  crumbs: Breadcrumb[];
  toolbarHeight: number;
  sideNav$ = new Observable((links) => {
    const newLinks = Coerce.getObjValues(FEATURES).filter(
      (feature: Feature) => feature.sideNavLink === true,
    );
    links.next(newLinks);
  });

  username$: Observable<unknown> = this.authService.selectAuthState().pipe(
    filter((state) => !!state),
    map((state: AuthState) => {
      const { lastName_IDT, firstName_IDT } = state.idToken.claims;
      return `${firstName_IDT} ${lastName_IDT}`.toUpperCase();
    }),
  );
  version = this.config.version?.version
    ? `v${this.config.version.version}`
    : '';

  activeClientId$ = this.clientFacade.activeClientId$;
  hasActiveClient$ = this.activeClientId$.pipe(map(Boolean));
  isSideNavVisible$: Observable<boolean>;
  isDataImport$: Observable<boolean>;

  drawerLinks$ = this.activeClientId$.pipe(
    map((clientId) =>
      Coerce.getObjValues(FEATURES)
        .filter((feature) => feature.sideNavLink === true)
        .map(
          ({ icon, name, path, pathExactMatch, iconRotate }: FeatureMetadata) =>
            ({
              icon,
              label: name,
              path: path.replace(':clientId', clientId),
              pathExactMatch,
              iconRotate,
            } as SsoDrawerLink),
        ),
    ),
  );

  releaseId$ = this.announcementFacade.latestReleaseId$;
  releaseVersion$ = this.announcementFacade.latestReleaseVersion$;
  showReleaseNotesBanner$ = combineLatest([
    this.releaseId$,
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(
        (event: NavigationEnd) =>
          this.routeService.releaseNotes().join() !== event?.urlAfterRedirects,
      ),
      startWith(
        this.routeService.releaseNotes().join() !== this.location.path(),
      ),
    ),
  ]).pipe(map(([id, isReleaseNoteRoute]) => !!id && !!isReleaseNoteRoute));

  crumbsReferences$: Observable<any> = combineLatest([
    this.clientFacade.getMainEntitiesReference().pipe(),
    this.roadmapFacade.roadmaps$.pipe(
      map((dataList) =>
        Object.assign(
          {},
          ...dataList.map((data) => ({ [data.id]: data.name })),
        ),
      ),
    ),
  ]).pipe(
    map(([clientData, roadmapData]) => {
      return {
        ...clientData,
        roadmapId: { ...roadmapData },
      };
    }),
  );

  constructor(
    private authService: AuthService,
    public userManager: UserStateManagerService,
    private routerStore: RouterStore,
    private pageStore: PageStore,
    private config: ConfigService,
    private i18n: I18NService,
    private clientFacade: ClientFacadeService,
    private router: Router,
    private wsService: WebSocketService,
    private roadmapFacade: RoadmapFacadeService,
    private roadmapVariationFacade: RoadmapVariationFacadeService,
    private campaignExportFacade: CampaignExportFacadeService,
    private campaignImportFacade: CampaignImportFacadeService,
    private dataImportFacade: DataImportFacadeService,
    private compareFacade: CompareFacadeService,
    private resultFacade: ResultFacadeService,
    private routeService: SSOToolRoutePathService,
    private location: Location,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private announcementFacade: AnnouncementFacadeService,
    private resultExportFacade: ResultExportFacadeService,
    private profileFacade: ProfileFacadeService,
    private bindataFacade: BinDataFacadeService,
    private roadmapImportFacade: RoadmapImportFacadeService,
    private visualizationFacade: RoadmapVisualizationFacadeService,
    private inputConverterService: InputConverterService,
  ) {
    super();
    this.registerSvgIcons();
  }

  private registerSvgIcons(): void {
    Object.entries(ROADMAP_CUSTOM_ICONS_FN).forEach(([name, iconFilename]) =>
      this.matIconRegistry.addSvgIcon(
        name,
        this.setPath(iconFilename, ROADMAP_CUSTOM_ICON_PATH),
      ),
    );
    Object.entries(GANTT_CUSTOM_ICONS_FN).forEach(([name, iconFilename]) =>
      this.matIconRegistry.addSvgIcon(
        name,
        this.setPath(iconFilename, GANTT_ICON_PATH),
      ),
    );
    Object.entries(TABLE_VIEW_CUSTOM_ICONS_FN).forEach(([name, iconFilename]) =>
      this.matIconRegistry.addSvgIcon(
        name,
        this.setPath(iconFilename, TABLE_VIEW_ICON_PATH),
      ),
    );
  }

  private setPath(iconFileName: string, iconPath: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      `${iconPath}/${iconFileName}`,
    );
  }

  /**
   * Removing the local storage data when a reload or an application close happen.
   * Data should not persist if the application is not used.
   * This will also be used to update the client specific data.
   */
  @HostListener('window:unload')
  @HostListener('window:beforeunload')
  clearAllLocalStorages() {
    this.resultFacade.clearLocalStorage();
    this.profileFacade.clearLocalStorage();
    this.bindataFacade.clearLocalStorage();
  }

  /**
   * Sets the toolbar height.
   * @param height the toolbar height.
   */
  setToolbarHeight(height: number): void {
    this.toolbarHeight = height;
  }

  /**
   * action taken when logout is clicked.
   */
  onClickLogout(): void {
    // TODO: when there's a handling of new version, put the remove item there.
    localStorage.removeItem('activeClientId');
    // this.oktaAuth.signOut();
    this.wsService.disconnect();
    this.router.navigate(this.routeService.login());
  }

  ngOnInit(): void {
    this.i18n.initTranslation();
    this.doActiveRouteChanges();
    this.initUser();
    this.initUserObservablesOnce();
    this.checkWebSocketConnection();
    this.checkSidenavVisibility();
  }

  isLoggedIn$ = this.authService.selectIsAuthenticated();

  /**
   * Initialize the user data
   */
  initUser() {
    this.authService.selectEmail().subscribe((email) => {
      /* istanbul ignore else */
      if (email) {
        this.userManager.getUserByEmail(email).subscribe();
        this.userManager.setSuperUserEmail(email);
        this.userManager.setAlphaRole(email);
        this.userManager.setCurrentUser(email);
      }
    });
  }

  initUserObservablesOnce() {
    this.authService
      .selectIsAuthenticated()
      .pipe(filter(Boolean), first())
      .subscribe(() => {
        this.announcementFacade.getList();
      });
  }

  private checkSidenavVisibility() {
    this.isSideNavVisible$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(
        (event: NavigationEnd) => !this.isGlobalPage(event?.urlAfterRedirects),
      ),
      startWith(!this.isGlobalPage(this.location.path())),
    );
  }

  private isGlobalPage(route: string): boolean {
    const globalRoutes = [
      [''],
      this.routeService.clients(),
      this.routeService.globalImport(),
      this.routeService.alphaRequests(),
      this.routeService.releaseNotes(),
      this.routeService.support(),
    ].map((route) => route.join());

    const exactMatch = globalRoutes.includes(route);

    const SPECIAL_PATTERNS = [/^\/clients\?code=.*/];

    return (
      exactMatch ||
      SPECIAL_PATTERNS.map((pattern) => pattern.test(route)).some((p) => p)
    );
  }

  doActiveRouteChanges() {
    this.routerStore.activeRoute$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((activeRoute) => {
        /* istanbul ignore else */
        if (activeRoute && activeRoute.data) {
          this.setBreadcrumbs(activeRoute);
          this.isDataImport$ = of(activeRoute.url?.includes('entities/import'));
        }
      });
  }

  /**
   * This function sets the appropriate metadata for the breadcrumbs
   */
  setBreadcrumbs(activeRoute: RouterStateUrl): void {
    this.pageStore.setBackground(activeRoute.data.background);
    this.pageStore.setName(activeRoute.data.name);
    this.pageStore.setTitle(activeRoute.data.title);
    this.crumbs = activeRoute.breadcrumbs;
  }

  checkWebSocketConnection() {
    this.authService
      .selectAuthState()
      .pipe(
        filter((authState) => authState?.idToken !== undefined),
        first(),
        switchMap((authState) => {
          /* istanbul ignore else */
          if (
            !authState ||
            !this.config.websocket ||
            !this.config.websocket.url
          ) {
            return of(undefined);
          }
          this.wsService.disconnect();
          this.wsService.connect(
            this.config.websocket.url,
            this.buildWebsocketParams(authState),
          );
          combineLatest([
            this.wsService.wsObservable$,
            this.clientFacade.activeClientId$,
          ])
            .pipe(
              takeWhile(() => this.wsService.isConnected()),
              takeUntil(this.componentDestroyed$),
            )
            .subscribe(([wsData, clientId]) => {
              this.processNotification(wsData, clientId);
            });
          return of(true);
        }),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe();
  }

  processNotification(wsData, clientId: string) {
    const data = Coerce.toObject(Coerce.toObject(wsData).data);
    if (data.clientId === clientId) {
      switch (data.generated_by) {
        // CLIENT
        case CLIENT_IMPORT:
          const tdbStatus = data.tdbStatus || '';

          this.dataImportFacade.updateImportStatus(
            clientId,
            data.importId,
            data.status,
            tdbStatus,
          );
          break;
        case CLIENT_IMPORT_LOG_DETAILS:
          this.dataImportFacade.updateImportLogDetails(
            data.importId,
            data.requestId,
            data.streamName,
          );
          break;
        case CLIENT_CASCADE_UPDATE:
          this.clientFacade.cascadeUpdate({
            clientId: clientId,
            updatedBy: data.updatedBy,
            updatedAt: data.updatedAt,
          });
          break;

        // CAMPAIGN
        case CAMPAIGN_IMPORT:
          this.campaignImportFacade.updateImportStatus(
            data.clientId,
            data.importId,
            data.status,
          );
          break;
        case CAMPAIGN_IMPORT_LOG_DETAILS:
          this.campaignImportFacade.updateImportLogDetails(
            data.importId,
            data.requestId,
            data.streamName,
          );
          break;
        case CAMPAIGN_EXPORT:
          this.campaignExportFacade.updateExportStatus(
            data.clientId,
            data.exportId,
            data.status,
            data.stages,
          );
          break;

        // PROFILE
        case PROFILE_MODIFY:
          this.profileFacade.updateModifyStatus(
            data.code,
            data.clientId,
            data.profileId,
            data.geoId,
          );
          break;

        //ROADMAP
        case COMPUTATION_HOOK:
          this.roadmapVariationFacade.reloadSingleRoadmapVariation(
            clientId,
            data.roadmapId,
            data.variationId,
            data.status,
            wsData?.type,
          );
          this.roadmapFacade.computeCanLaunchRoadmap(
            clientId,
            data.roadmapId,
            data.status,
          );
          break;
        case UPDATE_AFFECTED_ROADMAPS:
          this.roadmapFacade.reloadAffectedRoadmap(
            clientId,
            data.affected_roadmaps,
          );
          break;
        case MULTIPLE_COMPUTATION_HOOK:
          this.roadmapFacade.reloadMultipleRoadmap(
            clientId,
            data.roadmaps,
            data.status,
          );
          break;
        case COMPARE_HOOK:
          this.compareFacade.downloadComparisonResult(
            data.signedUrl,
            data.filesize,
          );
          break;
        case BASELINE_INDICATOR_COMPUTATION_HOOK:
          const baselineIndicatorData = data.baseline_indicators;
          this.resultFacade.updateBaselineIndicators(baselineIndicatorData);
          break;
        case RESULT_EXPORT_TRIGGER:
          this.resultExportFacade.processNotification(data);
          break;
        case REGENERATE_XLS:
          this.roadmapFacade.updateResultsXlsRegeneration(
            data.roadmapId,
            data.status,
          );
        case ROADMAP_IMPORT:
          this.roadmapImportFacade.updateImportStatus(
            data.clientId,
            data.roadmapId,
            data.importId,
            data.status,
          );
          break;
        case ROADMAP_IMPORT_LOG_DETAILS:
          this.roadmapImportFacade.updateImportLogDetails(
            data.importId,
            data.roadmapId,
            data.requestId,
            data.streamName,
          );
          break;
        case ROADMAP_CASCADE_UPDATE:
          const { roadmapId, updatedAt, updatedBy } = data;
          this.roadmapFacade.cascadeUpdate({
            clientId,
            roadmapId,
            updatedAt,
            updatedBy,
          });
          break;
        case ROADMAP_VISUALIZATION_NOTIF:
          const { visualizationId, name, type } = data;
          this.visualizationFacade.processNotification(
            clientId,
            data.roadmapId,
            visualizationId,
            name,
            type,
          );
          break;
        case INPUT_CONVERTER:
          const { outputUrl, logUrl, token, status } = data;
          this.inputConverterService.processOutput({
            outputUrl,
            logUrl,
            token,
            status,
          });
          break;
      }
    } else {
      switch (data.generated_by) {
        case CLIENT_DUPLICATE_UPDATE:
          this.clientFacade.updateDuplicateStatus(
            data.clientId,
            data.dupClientId,
            data.status,
          );
          break;
        case SANDBOX_CREATED_SUBJECT:
          this.clientFacade.updateDuplicateStatus(
            data.clientId,
            data.dupClientId,
            data.status,
            true,
          );
          break;
      }
    }
  }

  ngOnDestroy() {
    this.wsService.disconnect();
  }

  buildWebsocketParams(authState: Partial<AuthState>) {
    return {
      params: {
        idToken: this.createIDTokenForWSS(authState),
        accessToken: authState.accessToken.accessToken,
        'x-correlation-id': generateUuid(),
      },
      keepAlive: this.config.websocket.keepAlive,
      keepAliveIntervalMs: this.config.websocket.keepAliveIntervalMs,
      retryOnClose: this.config.websocket.retryOnClose,
      retryOnError: this.config.websocket.retryOnError,
      maxRetryAttempts: this.config.websocket.maxRetryAttempts,
      retryScalingDuration: this.config.websocket.retryScalingDuration,
      errorHandler: (error: any) => {
        this.wsService.logError(error);
        if (
          !this.wsService.refreshSubscription ||
          this.wsService.refreshSubscription.closed
        ) {
          this.wsService.refreshSubscription = this.authService
            .selectAuthState()
            .pipe(
              switchMap((authState: AuthState) => {
                const accessJwtToken = authState.accessToken.accessToken;
                if (this.currentAuthJwtToken !== accessJwtToken) {
                  this.currentAuthJwtToken = accessJwtToken;
                  return this.authService.selectOrRenewAccessToken();
                }
                this.wsService.retryConnectOnError();
                return of(authState);
              }),
              first(),
              catchError((errorResponse) => {
                this.wsService.retryConnectOnError();
                return throwError(errorResponse);
              }),
            )
            .subscribe();
        }
      },
    };
  }

  private createIDTokenForWSS(authState: AuthState): string {
    return `${authState.idToken.idToken},${authState.idToken.claims.nonce}`;
  }
}
