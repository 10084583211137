import { EnvConfig } from 'ssotool-core/config';
import { endpoints, version } from './environment-commons';

export const environment: EnvConfig = {
  name: 'algo',
  production: false,
  oktaAuth: {
    clientId: '0oa9uogsyaTyv4wBV0x7',
  },
  http: {
    timeoutMs: 300000,
    maxRetryAttempts: 2,
    retryScalingDuration: 1000,
    retryIncludedStatusCodes: [401],
    refreshTokenIncludedStatusCodes: [401]
  },
  api: {
    baseUrl: "https://algo.ssotool.te-ded.com/",
    endpoints
  },
  version,
  websocket: {
    url: "wss://wss.algo.ssotool.te-ded.com/",
    keepAlive: true,
    keepAliveIntervalMs: 480000,
    retryOnClose: true,
    retryOnError: true,
    retryScalingDuration: 5000
  },
  sentryDsn: 'https://a6edf8ae652145f0887455d86fb0d5b5@o258615.ingest.sentry.io/5691648',
  sentryRelease: `ssotool-${version}`,
  homeUrl: 'https://algo.ssotool.te-ded.com/login',
  faqUrl: null,
  trainingUrl: null
};