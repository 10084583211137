import { isJwtExpired } from 'jwt-check-expiration';
import { filter, map, Observable, tap } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { AccessToken, AuthState, IDToken } from '@okta/okta-auth-js';

import { LoggerService } from '../logger';

export const JWTCheckExpirationWrapper = {
  isJwtExpired,
};

// FROM DECLIC-APP
@Injectable({ providedIn: 'root' })
export class AuthService {
  private oktaState = inject(OktaAuthStateService);
  private oktaAuth = inject(OKTA_AUTH);
  private logger = inject(LoggerService);

  selectOrRenewAccessToken(): Observable<AccessToken> {
    return this.selectAuthState().pipe(
      map((state) => state.accessToken),
      filter(
        (token): token is AccessToken => token !== null && token !== undefined,
      ),
      tap((token) => this.renewAccessTokenIfExpired(token)),
      filter((token) => !this.isExpired(token.accessToken)),
      tap((token) =>
        this.logger.debug(`Latest access token: ${token.accessToken}`),
      ),
    );
  }

  selectOrRenewToken(
    tokenType: string = 'accessToken',
  ): Observable<IDToken | AccessToken> {
    return this.selectAuthState().pipe(
      map((state) =>
        tokenType === 'idToken' ? state.idToken : state.accessToken,
      ),
      filter(
        (token): token is AccessToken => token !== null && token !== undefined,
      ),
      tap((token: any) =>
        this.renewTokenIfExpired(
          tokenType,
          tokenType === 'idToken' ? token.idToken : token.accessToken,
        ),
      ),
      filter(
        (token: any) =>
          !this.isExpired(
            tokenType === 'idToken' ? token.idToken : token.accessToken,
          ),
      ),
      tap((token) => this.logger.debug(`Latest token: ${token}`)),
    );
  }

  selectIsAuthenticated(): Observable<boolean> {
    return this.selectAuthState().pipe(map((state) => state.isAuthenticated));
  }

  selectAuthState(): Observable<AuthState> {
    return this.oktaState.authState$;
  }

  signInWithRedirect(): void {
    this.oktaAuth.signInWithRedirect();
  }

  signOut(): void {
    this.oktaAuth.signOut();
  }

  getApiToken(): string {
    return this.oktaAuth.getIdToken();
  }

  selectApiNonce(): Observable<string> {
    return this.oktaState.authState$.pipe(
      map((state) => state.idToken),
      filter((token) => token !== null && token !== undefined),
      map((token) => token.claims.nonce),
    );
  }

  selectEmail(): Observable<string> {
    return this.selectAuthState().pipe(
      filter((state) => state.idToken !== null && state.idToken !== undefined),
      map((state) => state.idToken.claims.email),
    );
  }

  private isExpired(token: string): boolean {
    return JWTCheckExpirationWrapper.isJwtExpired(token);
  }

  private async renewAccessTokenIfExpired(token: AccessToken) {
    if (this.isExpired(token.accessToken)) {
      this.logger.debug('Renewing access token...');
      await this.oktaAuth.tokenManager.renew('accessToken');
    }
  }

  private async renewTokenIfExpired(tokenType: string, tokenValue: string) {
    if (this.isExpired(tokenValue)) {
      this.logger.debug(`Renewing ${tokenType} token...`);
      await this.oktaAuth.tokenManager.renew(tokenType);
    }
  }
}
