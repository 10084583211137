import {
  ApiConfig,
  EnvConfig,
  Features,
  HttpConfig,
  PubSubConfig,
  SupportConfig,
  Version,
  WebSocketConfig,
} from 'ssotool-core/config';
import {
  API_CONFIG,
  ENV_CONFIG,
  FEATURES_CONFIG,
  HTTP_CONFIG,
  PUBSUB_CONFIG,
  SHARED_VERSION_TOKEN,
  SUPPORT_CONFIG,
  WEBSOCKET_CONFIG,
} from 'ssotool-core/tokens';

import { Inject, Injectable, Optional } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  constructor(
    @Optional() @Inject(API_CONFIG) public api: ApiConfig,
    @Optional() @Inject(ENV_CONFIG) public environment: EnvConfig,
    @Optional() @Inject(FEATURES_CONFIG) public features: Features,
    @Optional() @Inject(HTTP_CONFIG) public http: HttpConfig,
    @Optional() @Inject(PUBSUB_CONFIG) public pubsub: PubSubConfig,
    @Optional() @Inject(SHARED_VERSION_TOKEN) public version: Version,
    @Optional() @Inject(WEBSOCKET_CONFIG) public websocket: WebSocketConfig,
    @Optional() @Inject(SUPPORT_CONFIG) public support: SupportConfig,
  ) {}
}
