import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { DownloadingStatus } from 'ssotool-app/+data-management/components/tools-drawer/input-converter.model';
import {
  DataImportFormModel,
  DataImportLog,
  DataImportResponseModel,
} from 'ssotool-app/+data-management/stores';
import { SignedUrl } from 'ssotool-app/app.model';
import { generateEndpoint } from 'ssotool-core/utils';
import { download, processDownloadedData } from 'ssotool-shared/services';
import { ConfigService } from 'ssotool-shared/services/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';

@Injectable()
export class DataImportAPIService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private toastService: HotToastService,
  ) {}

  private _downloadingStatus = new BehaviorSubject<DownloadingStatus>(false);
  downloadingStatus$ = this._downloadingStatus as Observable<DownloadingStatus>;

  loadingDownload$ = this.downloadingStatus$.pipe(map((status) => status));

  get(clientId: string, importId: string): Observable<any> {
    return this.http
      .get<any>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.dataImport.getImportDataInfo,
          clientId,
          importId,
        ),
      )
      .pipe(
        map((response) => this.mapImportDataToFrontend(response)),
        catchError((error) => throwError(error)),
      );
  }

  getList(clientId: string): Observable<DataImportResponseModel[]> {
    return this.http
      .get<DataImportResponseModel[]>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.dataImport.getImportDataList,
          clientId,
        ),
      )
      .pipe(catchError((error) => throwError(error)));
  }

  downloadTemplate(clientId: string) {
    return this.http
      .get(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.dataImport.downloadTemplate,
          clientId,
        ),
      )
      .pipe(
        mergeMap((downloadSignedUrl: any) =>
          this.http.get(downloadSignedUrl.signedUrl).pipe(
            map((response: any) => {
              const blob = new Blob([response], {
                type: 'applications/octet-stream',
              });
              const resUrl = window.URL.createObjectURL(blob);
              const anchor = document.createElement('a');
              anchor.download = downloadSignedUrl.filename;
              anchor.href = resUrl;
              anchor.click();
              return response;
            }),
          ),
        ),
        catchError((error) => throwError(error)),
      );
  }

  downloadTemplateByType(templateType: string) {
    const endpoint = generateEndpoint(
      this.config.api.baseUrl,
      this.config.api.endpoints.dataImport.downloadTemplateByType,
      templateType,
    );

    return this.http
      .get(endpoint)
      .pipe(
        tap(() => {
          this._downloadingStatus.next(true);
        }),
        mergeMap((downloadSignedUrl: any) =>
          this.http.get(downloadSignedUrl.signedUrl).pipe(
            map((response: any) => {
              const blob = new Blob([response], {
                type: 'applications/octet-stream',
              });
              const resUrl = window.URL.createObjectURL(blob);
              const anchor = document.createElement('a');
              anchor.download = downloadSignedUrl.filename;
              anchor.href = resUrl;
              anchor.click();
              return response;
            }),
          ),
        ),
        tap(() => {
          this._downloadingStatus.next(false);
        }),
        catchError((error) => {
          this.toastService.error('Downloading of Template Failed');
          this._downloadingStatus.next(false);
          return throwError(() => new Error(error));
        }),
      )
      .subscribe();
  }

  downloadClientData(clientId: string, importId: string) {
    return this.http
      .get(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.dataImport.getDownloadSignedUrl,
          clientId,
          importId,
        ),
      )
      .pipe(
        mergeMap((downloadSignedUrl: any) =>
          this.http
            .get(downloadSignedUrl.signedUrl, {
              responseType: 'blob',
            })
            .pipe(
              map((response: any) => {
                const blob = new Blob([response], {
                  type: 'applications/octet-stream',
                });
                const resUrl = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = downloadSignedUrl.filename;
                anchor.href = resUrl;
                anchor.click();
                return response;
              }),
            ),
        ),
        catchError((error) => throwError(error)),
      );
  }

  importData(clientId: string, importData: DataImportFormModel) {
    return this.http
      .post(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.dataImport.importData,
          clientId,
        ),
        {
          description: importData.description,
          bau_timeline_type: importData.clientTimelineType,
        },
      )
      .pipe(
        mergeMap((uploadResponse: any) =>
          this.http
            .get(
              generateEndpoint(
                this.config.api.baseUrl,
                this.config.api.endpoints.dataImport.getImportSignedUrl,
                clientId,
                uploadResponse.importId,
                importData.file.name,
              ),
            )
            .pipe(
              mergeMap((uploadSignedUrl: any) =>
                this.http
                  .put(uploadSignedUrl.signedUrl, importData.file)
                  .pipe(
                    map(() => this.mapImportDataToFrontend(uploadResponse)),
                  ),
              ),
            ),
        ),
        catchError((error) => throwError(error)),
      );
  }

  deleteImport(clientId: string, importId: string) {
    return this.http
      .delete(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.dataImport.deleteImport,
          clientId,
          importId,
        ),
      )
      .pipe(catchError((error) => throwError(error)));
  }

  downloadErrorLogs(clientId: string, importId: string) {
    return this.http
      .get(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.dataImport.getImportLogs,
          clientId,
          importId,
        ),
      )
      .pipe(
        mergeMap((logDownloadSignedUrl: SignedUrl) =>
          this.http
            .get(logDownloadSignedUrl.signedUrl, {
              reportProgress: true,
              observe: 'events',
              responseType: 'blob',
            })
            .pipe(
              download(),
              processDownloadedData<string>(),
              map((logs) => this.parseMessages(logs)),
            ),
        ),
      );
  }

  mapImportDataToFrontend(data: any) {
    return {
      clientId: data.clientId,
      importId: data.importId,
      owner: data.owner,
      description: data.description,
      createdAt: data.createdAt,
      filename: data.filename,
      status: data.status,
    };
  }

  parseMessages(messages: any) {
    if (messages) {
      let parsedMessages: any;
      try {
        parsedMessages = JSON.parse(messages);
        return this.groupDataByModule(parsedMessages?.issues);
      } catch (error) {
        return messages;
      }
    } else {
      return messages;
    }
  }

  groupDataByModule(messages: DataImportLog[]) {
    const newMessages = {};
    messages?.forEach((data: DataImportLog) => {
      let module = data?.location?.module;
      if (module === undefined) {
        module = 'Internal Error';
      }
      newMessages[module] = newMessages[module]
        ? [...newMessages[module], data]
        : [data];
    });
    return newMessages;
  }
}
