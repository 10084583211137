import { Observable, pipe, throwError } from 'rxjs';
import { catchError, first, mergeMap, mergeMapTo } from 'rxjs/operators';
import { AuthService } from 'ssotool-app/shared/services/auth/auth.service';

import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AUTH_TYPE, authUtil, MIME_TYPE } from '@oculus/auth/amplify';

import { MultiTranslateHttpLoader } from '../utils/multi-translate-http-loader.util';

@Injectable()
export class RefreshInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // See note on multi-translate-http-loader.util.ts
    if (request.headers.has(MultiTranslateHttpLoader.InterceptorSkipHeader)) {
      const headers = request.headers.delete(
        MultiTranslateHttpLoader.InterceptorSkipHeader,
      );
      return next.handle(request.clone({ headers }));
    }
    return this.authService.selectAuthState().pipe(
      this.updateHeaders(request, next),
      catchError((error: HttpErrorResponse) => {
        if (
          authUtil.isHttpErrorResponse(error) &&
          authUtil.isErrorTokenExpired(error) &&
          authUtil.isStatusCodeForRefreshToken(error.status)
        ) {
          return this.authService
            .selectOrRenewToken()
            .pipe(
              mergeMapTo(this.authService.selectAuthState()),
              this.updateHeaders(request, next),
            );
        }
        return throwError(error);
      }),
    );
  }

  protected updateHeaders<T = any>(
    request: HttpRequest<T>,
    next: HttpHandler,
  ): any {
    return pipe(
      first<T>(),
      mergeMap((state: any) =>
        next.handle(request.clone(this.getUpdatedHeaders(state, request))),
      ),
    );
  }

  protected getUpdatedHeaders(
    state: any,
    request: HttpRequest<unknown>,
  ): { [key: string]: any } {
    const loggedIn = state.isAuthenticated;
    const idToken = state.idToken as Record<string, any>;
    const { url } = request;
    const contentType = MIME_TYPE.APPLICATION.JSON;

    let headers = request.headers.set('Content-Type', contentType);
    if (loggedIn && !authUtil.isFileUpload(url)) {
      const authorization = `${AUTH_TYPE.BEARER} ${idToken.idToken}`;
      headers = headers.set('Authorization', authorization);
    }

    return { headers };
  }
}
