import { LoginGuard } from 'ssotool-core/guards';
import { HOME_URL_REDIRECT_PROVIDER } from 'ssotool-core/tokens';
import { OKTA_CALLBACK_PATH } from 'ssotool-env';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';

import { CampaignSubpageResolverService } from './+campaign/services/campaign-subpage-resolver.service';
import { ClientDataSubpageResolverService } from './+client/services/client-data-subpage-resolver.service';
import { ClientSubpageResolverService } from './+client/services/client-subpage-resolver.service';
import { DataImportComponent } from './+data-management/data-import.component';
import { dataManagementResolver } from './+data-management/services/data-management-subpage-resolver.service';
import { EntitiesPageComponent } from './+entities/entities-page.component';
import { BindataSubpageResolverService } from './+entities/services/bindata-subpage.resolver.service';
import { ErrorPageComponent } from './+error/error-page.component';
import { LoginPageComponent } from './+login';
import { PROJECT_NAME } from './app.references';
import { Features } from './core/config';

export const APP_SEPARATOR = ' | ';
export const TITLE_SUFFIX = APP_SEPARATOR + PROJECT_NAME;

export const SUPPORT = {
  TYPE_OF_REQUESTS: [
    { name: 'Bug', value: 'TR0000' },
    { name: 'How To', value: 'TR0001' },
    { name: 'Issue Analysis', value: 'TR0002' },
    { name: 'Help to Understand Results', value: 'TR0003' },
    { name: 'Feature Request', value: 'TR0004' },
    { name: 'Sales/License Question', value: 'TR0005' },
  ],
  ISSUE_TYPES: {
    BUG: 'BUG',
    TASK: 'TASk',
    SUPPORT: 'SUPPORT',
  },
};

export const FEATURES: Features = {
  login: {
    name: 'Login',
    path: 'login',
  },
  cognito: {
    name: 'Cognito',
    path: 'cognito',
  },
  home: {
    name: 'Home',
    path: 'home',
    preload: true,
    title: 'Home' + TITLE_SUFFIX,
    toolbarLink: false,
  },
  clients: {
    name: 'My Clients',
    path: 'clients',
    pathExactMatch: true,
    preload: true,
    title: 'Switch Client' + TITLE_SUFFIX,
    sideNavLink: false,
    sideNav: false,
    breadcrumb: 'Switch Client',
    icon: 'home',
  },
  dataManagement: {
    name: 'Data Management',
    path: 'clients/:clientId/data',
    preload: true,
    title: 'Client Data' + TITLE_SUFFIX,
    sideNavLink: true,
    sideNav: true,
    breadcrumb: 'Client Data',
    icon: 'add_chart',
  },
  entities: {
    name: 'Client Data',
    path: 'clients/:clientId/entities',
    preload: true,
    title: 'Client Data' + TITLE_SUFFIX,
    sideNavLink: true,
    sideNav: true,
    breadcrumb: 'Client Data',
    icon: 'equalizer',
  },
  ambition: {
    name: 'Client Ambition',
    path: 'clients/:clientId/ambition',
    preload: true,
    title: 'Client Ambition' + TITLE_SUFFIX,
    sideNavLink: true,
    sideNav: true,
    breadcrumb: 'Client Ambition',
    icon: 'flag',
  },
  campaigns: {
    name: 'Campaigns',
    path: 'clients/:clientId/campaigns',
    preload: true,
    title: 'Campaigns' + TITLE_SUFFIX,
    sideNavLink: true,
    sideNav: true,
    breadcrumb: 'Campaigns',
    icon: 'campaign',
  },
  portfolio: {
    name: 'Client Results',
    path: 'clients/:clientId/portfolio',
    preload: true,
    title: 'Client Results' + TITLE_SUFFIX,
    sideNavLink: false,
    sideNav: true,
    breadcrumb: 'Client Results',
    icon: 'public',
  },
  compare: {
    name: 'Compare',
    path: 'compare',
    preload: true,
    title: 'Compare' + TITLE_SUFFIX,
    sideNavLink: false,
    sideNav: true,
    breadcrumb: 'Compare',
    icon: 'flip_camera_android',
  },
  roadmaps: {
    name: 'Roadmaps',
    path: 'clients/:clientId/roadmaps',
    preload: true,
    title: 'Roadmaps' + TITLE_SUFFIX,
    sideNavLink: true,
    sideNav: true,
    breadcrumb: 'Roadmaps',
    icon: 'route',
    iconRotate: true,
  },
  support: {
    name: 'Ask for Support',
    path: 'support',
    preload: true,
    title: 'Ask for Support' + TITLE_SUFFIX,
    sideNavLink: false,
    sideNav: false,
    breadcrumb: 'Ask for Support',
    icon: 'contact_support',
  },
  global_import: {
    name: 'Global Import',
    path: 'globalImport',
    preload: true,
    title: 'Global Import' + TITLE_SUFFIX,
    sideNavLink: false,
    sideNav: false,
    breadcrumb: 'Global Import',
    icon: 'sync_alt',
  },
  alpha_request: {
    name: 'Alpha Request',
    path: 'requests',
    preload: true,
    title: 'Alpha Request' + TITLE_SUFFIX,
    sideNavLink: false,
    sideNav: false,
    breadcrumb: 'Alpha Request',
    icon: 'sync_alt',
  },
  error: {
    name: 'Error',
    path: 'error',
    title: 'Error' + TITLE_SUFFIX,
  },
  prototype: {
    name: 'Prototype',
    path: 'prototype',
    title: 'Prototype' + TITLE_SUFFIX,
    sideNavLink: false,
    breadcrumb: 'Prototype',
    sideNav: false,
    icon: 'wb_incandescent',
  },
  releaseNotes: {
    name: 'Release Notes',
    path: 'release-notes',
    preload: true,
    title: 'Release Notes' + TITLE_SUFFIX,
    sideNavLink: false,
    sideNav: false,
    breadcrumb: 'Release Notes',
    icon: 'sync_alt',
  },
};
// istanbul ignore next
export const routes: Routes = [
  {
    path: FEATURES.prototype.path,
    loadChildren: () =>
      import('./+prototype/prototype.module').then((m) => m.PrototypeModule),
    data: FEATURES.prototype,
    canActivate: [OktaAuthGuard],
  },
  {
    path: FEATURES.support.path,
    loadChildren: () =>
      import('./shared/modules/support').then((m) => m.EyesSupportModule),
    data: FEATURES.support,
    canActivate: [OktaAuthGuard],
  },
  {
    path: FEATURES.global_import.path,
    loadChildren: () =>
      import('./+global-import/global-import.module').then(
        (m) => m.GlobalImportModule,
      ),
    data: FEATURES.global_import,
    canActivate: [OktaAuthGuard],
  },
  {
    path: FEATURES.releaseNotes.path,
    loadChildren: () =>
      import('./+release-notes/release-notes.module').then(
        (m) => m.ReleaseNotesModule,
      ),
    data: FEATURES.releaseNotes,
    canActivate: [OktaAuthGuard],
  },
  {
    path: FEATURES.alpha_request.path,
    loadChildren: () =>
      import('./+alpha-request/alpha-request.module').then(
        (m) => m.AlphaRequestModule,
      ),
    data: FEATURES.alpha_request,
    canActivate: [OktaAuthGuard],
  },
  {
    path: FEATURES.clients.path,
    loadChildren: () =>
      import('./+client/client.module').then((m) => m.ClientModule),
    data: FEATURES.clients,
    canActivate: [OktaAuthGuard],
  },
  {
    path: `${FEATURES.clients.path}/:clientId`,
    redirectTo: 'clients/:clientId/entities',
    pathMatch: 'full',
  },
  {
    path: FEATURES.dataManagement.path,
    component: DataImportComponent,
    loadChildren: () =>
      import('./+data-management/data-import.module').then(
        (m) => m.DataImportModule,
      ),
    data: FEATURES.dataManagement,
    canActivate: [OktaAuthGuard],
    resolve: {
      client: ClientSubpageResolverService,
      clientData: ClientDataSubpageResolverService,
      imports: dataManagementResolver,
    },
  },
  {
    path: FEATURES.entities.path,
    component: EntitiesPageComponent,
    loadChildren: () =>
      import('./+entities/entities.module').then((m) => m.EntitiesModule),
    data: FEATURES.entities,
    canActivate: [OktaAuthGuard],
    resolve: {
      client: ClientSubpageResolverService,
      clientData: ClientDataSubpageResolverService,
      bindata: BindataSubpageResolverService,
    },
  },
  {
    path: FEATURES.ambition.path,
    loadChildren: () =>
      import('./+ambition/ambition.module').then((m) => m.AmbitionModule),
    data: FEATURES.ambition,
    canActivate: [OktaAuthGuard],
    resolve: {
      client: ClientSubpageResolverService,
      clientData: ClientDataSubpageResolverService,
    },
  },
  {
    path: FEATURES.campaigns.path,
    loadChildren: () =>
      import('./+campaign/campaign.module').then((m) => m.CampaignModule),
    data: FEATURES.campaigns,
    canActivate: [OktaAuthGuard],
    resolve: {
      client: ClientSubpageResolverService,
      clientData: ClientDataSubpageResolverService,
      campaigns: CampaignSubpageResolverService,
    },
  },
  {
    path: FEATURES.roadmaps.path,
    loadChildren: () =>
      import('./+roadmap/roadmap.module').then((m) => m.RoadmapModule),
    data: FEATURES.roadmaps,
    canActivate: [OktaAuthGuard],
    resolve: {
      client: ClientSubpageResolverService,
      clientData: ClientDataSubpageResolverService,
      campaigns: CampaignSubpageResolverService,
    },
  },
  {
    path: FEATURES.home.path,
    canActivate: [HOME_URL_REDIRECT_PROVIDER],
    component: ErrorPageComponent,
  },
  {
    path: FEATURES.login.path,
    component: LoginPageComponent,
    canActivate: [LoginGuard],
  },
  {
    path: FEATURES.error.path,
    component: ErrorPageComponent,
    data: FEATURES.error,
  },
  {
    path: '',
    loadComponent: () =>
      import('./+app-landing/app-landing.component').then(
        (m) => m.AppLandingComponent,
      ),
  },
  {
    path: OKTA_CALLBACK_PATH,
    component: OktaCallbackComponent,
  },
  {
    path: '**',
    redirectTo: FEATURES.error.path,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
