import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { UserDetails } from 'ssotool-app/+client/components/share-client/share-client.model';
import {
  SYSTEM_USER_DETAILS,
  SYSTEM_USER_IDS,
} from 'ssotool-app/app.references';
import { generateEndpoint } from 'ssotool-core/utils';
import { ConfigService } from 'ssotool-shared/services/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Coerce } from '../helpers';
import { Entity, EntityState, EntityStateManager } from './state-management';

export interface UserGroup {
  id: string;
  groupName: string;
}
export interface User extends Entity {
  firstName?: string;
  lastName?: string;
  fullname?: string;
  email?: string;
  requestedBy?: string;
  name?: string;
  groups?: UserGroup[];
}

const ALPHA_ROLES = {
  admin: 'ADMIN',
  consultant: 'CONSULTANT',
};
const ALPHA_CONSULTANT = 'alpha_consultant';

interface UserState extends EntityState<User> {
  loaded: boolean;
}
@Injectable()
export class UserStateManagerService extends EntityStateManager<
  User,
  UserState
> {
  private _superUser = new BehaviorSubject<boolean>(false);
  private _alphaRole = new BehaviorSubject<string[]>([]);
  private _authUserEmail = new BehaviorSubject<string>('');
  private _hasAlphaRole = new BehaviorSubject<boolean>(false);
  private _currentUser = new BehaviorSubject<User>(null);
  private _isExternalUser = new BehaviorSubject<boolean>(false);
  private USER_GROUPS: Record<string, string> = {
    EXTERNAL: `ssotool-${this.config.environment.name}-external`,
  };

  emailDataList$ = this.dataList$.pipe(
    map((dataList) =>
      Object.assign(
        {},
        ...dataList.map((data) => ({ [data.email]: { ...data } })),
      ),
    ),
  );
  getEmailLoading$ = new BehaviorSubject<boolean>(false);
  getUserIdLoading$ = new BehaviorSubject<boolean>(false);
  searchEmailLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient, private config: ConfigService) {
    super();
  }

  get(id: string) {
    return SYSTEM_USER_IDS.includes(id) ||
      Coerce.toString(id).indexOf('-') === -1
      ? { id, ...SYSTEM_USER_DETAILS, loaded: true }
      : super.get(id);
  }

  getUserGroup(email: string): Observable<any> {
    return this.emailDataList$.pipe(map((list) => list[email]));
  }

  setSuperUserEmail(email: string) {
    this._authUserEmail.next(email);
    this.setSuperUser(email);
  }

  getSuperUserEmail() {
    return this._authUserEmail;
  }

  setSuperUser(email: string) {
    this.getUserGroup(email).subscribe((data) => {
      /* istanbul ignore else */
      if (data) {
        this._superUser.next(
          Coerce.toArray<any>(data.groups, []).some(
            (_g) =>
              _g.groupName ===
              `ssotool-${this.config.environment.name}-library_updater`,
          ),
        );
      }
    });
  }

  isSuperUser() {
    return this._superUser;
  }

  getRoles(preferences): string[] {
    return JSON.parse(preferences?.['roles'] || '[]');
  }

  setAlphaRole(email: string) {
    this.getUserGroup(email).subscribe((data) => {
      /* istanbul ignore else */
      if (data) {
        const isAdmin = Coerce.toArray<any>(data.groups, []).some(
          (_g) =>
            _g.groupName ===
            `ssotool-${this.config.environment.name}-alpha_admin`,
        );
        const isConsultant = this.getRoles(data?.preferences).includes(
          ALPHA_CONSULTANT,
        );
        const alphaRole = [];

        /* istanbul ignore else */
        if (isAdmin) {
          alphaRole.push(ALPHA_ROLES.admin);
        }
        /* istanbul ignore else */
        if (isConsultant) {
          alphaRole.push(ALPHA_ROLES.consultant);
        }

        this._alphaRole.next(alphaRole);
        this._hasAlphaRole.next(!!alphaRole.length);
      }
    });
  }

  hasAlphaRole() {
    return this._hasAlphaRole;
  }

  isAlphaAdmin() {
    return this._alphaRole.pipe(
      map((roles) => roles.includes(ALPHA_ROLES.admin)),
    );
  }

  isExternalUser(): Observable<boolean> {
    return this._isExternalUser;
  }

  setCurrentUser(email) {
    this.getUserGroup(email).subscribe((data: User) => {
      this._currentUser.next(data);
      this._isExternalUser.next(
        this.isGroupedAs(this.USER_GROUPS.EXTERNAL, data?.groups),
      );
    });
  }

  private isGroupedAs(userGroup: string, groups: UserGroup[]): boolean {
    return !!(groups || []).find((group) => group.groupName === userGroup);
  }

  getCurrentUser(): User {
    return this._currentUser.value;
  }

  // selectors
  selectEmail(email: string) {
    let selectedUser = null;
    this.getCurrentState().data.forEach((user) => {
      /* istanbul ignore else */
      if (user.email === email) {
        selectedUser = user;
      }
    });
    return selectedUser;
  }

  initialState(): UserState {
    return {
      data: new Map(),
      loaded: false,
    };
  }

  initializeUserLoading(id: string) {
    if (
      SYSTEM_USER_IDS.includes(id) ||
      Coerce.toString(id).indexOf('-') === -1
    ) {
      this.upsert({ id, ...SYSTEM_USER_DETAILS, loaded: true });
    } else {
      this.upsert({ id: id, loaded: false });
    }
  }

  searchUsersByName(name: string) {
    this.searchEmailLoading$.next(true);

    return this.httpClient
      .post(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.user.searchUsersByName,
        ),
        {
          name,
        },
      )
      .pipe(
        tap((users: UserDetails[]) => {
          this.searchEmailLoading$.next(false);
        }),
        catchError((error) => throwError('error')),
      );
  }

  getUserById(id: string) {
    this.initializeUserLoading(id);
    this.getUserIdLoading$.next(true);
    this.httpClient
      .get<any>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.user.getUserById,
          id,
        ),
      )
      .subscribe(
        (user) => {
          /* istanbul ignore else */
          if (user) {
            this.upsert({ ...user, loaded: true });
          }
          this.getUserIdLoading$.next(false);
        },
        (error) => {
          this.getUserIdLoading$.next(false);
          return throwError(error);
        },
      );
  }

  getUserByEmail(email: string) {
    this.getEmailLoading$.next(true);
    return this.httpClient
      .get<any>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.user.getUserByEmail,
          email,
        ),
      )
      .pipe(
        tap((userData) => {
          this.getEmailLoading$.next(false);
          /* istanbul ignore else */
          if (userData) {
            this.upsert({ ...userData, loaded: true });
          }
        }),
        catchError((error) => {
          this.getEmailLoading$.next(false);
          return throwError(error);
        }),
      );
  }

  getCurrentUserDuplicateClientsPref(): Observable<string[]> {
    return this._currentUser.asObservable().pipe(
      filter((user) => !!user),
      mergeMap((currentUser) =>
        this.getUserGroup(currentUser?.email).pipe(
          map((userGroup) =>
            JSON.parse(userGroup?.preferences?.['duplicateClients'] || '[]'),
          ),
        ),
      ),
    );
  }

  reloadCurrentUserPref() {
    const currUser = this.getCurrentUser();
    if (currUser?.email) {
      this.getUserByEmail(currUser?.email).pipe(take(1)).subscribe();
      this.getCurrentUserDuplicateClientsPref().pipe(take(1)).subscribe();
    }
  }
}
