import { Component, inject } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';

@Component({
  selector: 'sso-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginPageComponent {
  private oktaAuth = inject(OKTA_AUTH);

  onLogin() {
    this.oktaAuth.signInWithRedirect();
  }
}
