import { combineLatest, iif, of } from 'rxjs';
import { first, map, mergeMap, skipWhile, tap } from 'rxjs/operators';
import {
  ClientDataEntity,
  ClientFacadeService,
  Site,
} from 'ssotool-app/+client';
import {
  AnalysisMapper,
  EconomicsCurvesMapper,
  EnvironmentalCurvesMapper,
  SustainabilityCurvesMapper,
  WorldmapKpisMapper,
} from 'ssotool-app/+roadmap/services';
import { SignedUrl } from 'ssotool-app/app.model';
import { MOCK_CAMPAIGN_MAPPER, REFERENCES } from 'ssotool-app/app.references';
import { Coerce } from 'ssotool-app/shared/helpers';
import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';
import { generateEndpoint } from 'ssotool-core/utils';
import { ConfigService } from 'ssotool-shared/services/config';
import {
  download,
  processDownloadedData,
} from 'ssotool-shared/services/download';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseGraphMapper } from '../../services/base-graph.mapper';
import { KPIType } from '../portfolio.model';
import { ResultFacadeService } from './result-facade.service';
import { ResultLocalStorageService } from './result-local-storage.service';
import { ResultGetParameters } from './result-parameters.model';
import {
  BackendBaselineIndicators,
  BackendBaselineIndicatorsOutput,
  BackendBaselineIndicatorTrigger,
  BackendProjectDetails,
  BackendResult,
  BackendSankeyType,
  BaselineIndicators,
  BaselineIndicatorTrigger,
  ClientReferenceData,
  PortfolioCurveEntities,
  ProjectDetails,
  RawCoreData,
  Result,
  ResultData,
  ResultReferenceCampaign,
  ResultReferenceEntities,
  SankeyModel,
} from './result.model';
import { OUTPUT_RESULT_ZIP } from './result.reference';

@Injectable()
export class ResultApiService {
  getRequest(param: ResultGetParameters) {
    const { clientId, roadmapId, variationId } = param;
    return this.httpClient
      .get(this.makeGetEndpoint(param), {
        params: {
          variationId,
        },
      })
      .pipe(
        mergeMap((resultDownloadSignedUrl: SignedUrl) =>
          this.httpClient
            .get(resultDownloadSignedUrl.signedUrl, {
              reportProgress: true,
              observe: 'events',
              responseType: 'blob',
            })
            .pipe(
              download(resultDownloadSignedUrl.filesize),
              tap((data) =>
                this.resultFacade.updateLoadedProgress(
                  roadmapId,
                  data.progress,
                ),
              ),
              tap((data) =>
                this.resultFacade.updateLoadedVariationProgress(
                  roadmapId,
                  variationId,
                  data.progress,
                ),
              ),
              processDownloadedData<string>(),
              mergeMap((rawResult: string) =>
                this.clientFacade.dataLoaded$(clientId).pipe(
                  skipWhile((loaded) => !loaded),
                  mergeMap(() =>
                    combineLatest([
                      this.clientFacade.selectCompanies$(clientId),
                      this.clientFacade.selectGeos$(clientId),
                      this.clientFacade.selectExistingAssets$(clientId),
                      this.clientFacade.selectSites$(clientId),
                    ]).pipe(
                      first(),
                      mergeMap(([companies, geos, existingAssets, sites]) => {
                        const jsonData: RawCoreData = JSON.parse(
                          rawResult.replace(/\bNaN\b/g, 'null'),
                        );
                        let result: ResultData;
                        /* istanbul ignore else */
                        if (jsonData?.meta) {
                          result = {
                            ...(jsonData?.roadmaps?.[roadmapId]?.[
                              variationId
                            ] || jsonData?.roadmaps?.[roadmapId]),
                            id_name_mapping: {
                              ...jsonData?.common_data?.id_name_mapping,
                              existing_mapping: existingAssets,
                            },
                            campaign_data: jsonData?.common_data?.campaign_data,
                          };
                        }

                        return this.localStorage
                          .addMainResult(
                            this.mapToFrontend(
                              {
                                clientId,
                                roadmapId,
                                variationId,
                                result,
                              },
                              geos,
                              companies,
                              sites,
                            ),
                          )
                          .pipe(
                            map(() => ({
                              id: roadmapId,
                              clientId,
                            })),
                          );
                      }),
                    ),
                  ),
                ),
              ),
            ),
        ),
      );
  }

  getBaselineIndicators(clientId: string, roadmapId: string) {
    return this.httpClient
      .get<BackendBaselineIndicatorTrigger>(
        this.makeGetBaselineIndicatorsEndpoint(clientId, roadmapId),
      )
      .pipe(
        map((response: BackendBaselineIndicatorTrigger) => {
          return {
            isComputing: response.isComputing,
            message: response.message,
            clientId,
            roadmapId,
          } as BaselineIndicatorTrigger;
        }),
      );
  }

  getMultipleBaselineIndicators(clientId: string) {
    return this.httpClient
      .get<{ roadmapIds: string[] }>(
        this.makeGetMultipleBaselineIndicatorsEndpoint(clientId),
      )
      .pipe(map(({ roadmapIds }: { roadmapIds: string[] }) => roadmapIds));
  }

  updateBaselineIndicatorsOutput(data: BackendBaselineIndicators) {
    return of(data).pipe(
      mergeMap(() =>
        iif(
          () => !!data?.signedUrl,
          of(data).pipe(
            mergeMap(() =>
              this.httpClient
                .get(data.signedUrl, {
                  observe: 'events',
                  responseType: 'blob',
                })
                .pipe(
                  download(),
                  processDownloadedData<string>(),
                  map((output: string) =>
                    this.mapBaselineIndicatorsToFrontend(
                      data,
                      JSON.parse(output),
                    ),
                  ),
                ),
            ),
          ),
          of(this.mapBaselineIndicatorsToFrontend(data)),
        ),
      ),
    );
  }

  downloadComputationLogs(
    clientId: string,
    roadmapId: string,
    variationId: string,
  ) {
    return this.httpClient
      .get(this.makeGetLogEndpoint(clientId, roadmapId), {
        params: { variationId },
      })
      .pipe(
        mergeMap((logDownloadSignedUrl: SignedUrl) =>
          this.httpClient.get(logDownloadSignedUrl.signedUrl).pipe(
            map((response: BlobPart) => {
              this.downloadFile(response, logDownloadSignedUrl.filename);
              return response;
            }),
          ),
        ),
      );
  }

  downloadComputationInputs(
    clientId: string,
    roadmapId: string,
    variationId: string,
  ) {
    return this.httpClient
      .get(this.makeGetInputsEndpoint(clientId, roadmapId), {
        params: { variationId },
      })
      .pipe(
        mergeMap((inputDownloadSignedUrl: any) =>
          this.httpClient
            .get(inputDownloadSignedUrl?.signedUrl, {
              responseType: 'blob',
            })
            .pipe(
              map((response: BlobPart) => {
                this.downloadFile(response, inputDownloadSignedUrl.filename);
                return response;
              }),
            ),
        ),
      );
  }

  downloadResultExcel(
    clientId: string,
    roadmapId: string,
    variationId: string,
  ) {
    return this.httpClient
      .get(this.makeGetResultExcelEndpoint(clientId, roadmapId), {
        params: { variationId },
      })
      .pipe(
        mergeMap((logDownloadSignedUrl: SignedUrl) =>
          this.httpClient
            .get(logDownloadSignedUrl.signedUrl, {
              responseType: 'blob',
            })
            .pipe(
              map((response: BlobPart) => {
                this.downloadFile(response, logDownloadSignedUrl.filename);
                return response;
              }),
            ),
        ),
      );
  }

  downloadComputationResult(
    clientId: string,
    roadmapId: string,
    variationId: string,
  ) {
    return this.httpClient
      .get(this.makeGetEndpoint({ clientId, roadmapId, variationId }, true), {
        params: { variationId },
      })
      .pipe(
        mergeMap((inputDownloadSignedUrl: any) =>
          this.httpClient
            .get(inputDownloadSignedUrl?.signedUrl, {
              responseType: 'blob',
            })
            .pipe(
              map((response: BlobPart) => {
                this.downloadFile(response, OUTPUT_RESULT_ZIP);
                return response;
              }),
            ),
        ),
      );
  }

  mapToBackend(entity: any): any {
    return { ...entity };
  }

  mapToFrontend(
    { roadmapId, clientId, variationId, result }: BackendResult,
    geographies: ClientDataEntity,
    companies: ClientDataEntity,
    sites: ClientDataEntity<Site> = {},
  ): Result {
    return {
      id: this.localStorage.createId(roadmapId, variationId),
      clientId: clientId,
      analysis: AnalysisMapper.mapToFrontend(result?.snapshot),
      worldMap: WorldmapKpisMapper.mapToFrontend(result?.worldmap_kpis),
      curves: this.mapCurves(
        result?.detailed_results,
        result?.id_name_mapping,
        result?.campaign_data,
        {
          geographies,
          companies,
          sites,
        },
      ),
      indexes: result?.detailed_results?.index,
      filterOptions: {
        years: result?.detailed_results?.index,
        lever: this.getFilters(
          Coerce.getObjValues(result?.campaign_data),
          'campaignType',
        ).concat(['Existing asset', 'Market']),
        process: this.getFilters(
          Coerce.getObjValues(result?.id_name_mapping?.process_mapping),
        ),
        fluid: this.getFilters(
          Coerce.getObjValues(result?.id_name_mapping?.fluid_mapping),
        ),
        campaign: [
          ...this.getFilters(
            Coerce.getObjValues(result?.id_name_mapping?.campaign_mapping),
          ),
          ...this.getFilters(
            Coerce.getObjValues(result?.id_name_mapping?.existing_mapping),
          ),
        ],
        geography: this.getFilters(
          Coerce.getObjValues(result?.id_name_mapping?.site_mapping),
        ),
        entity: this.getFilters(
          Coerce.getObjValues(result?.id_name_mapping?.company_entity_mapping),
        ),
      },
      projects: this.mapProjects(
        result?.projects,
        result?.id_name_mapping,
        result?.campaign_data,
        {
          geographies,
          companies,
          sites,
        },
      ),
      sankey: this.mapSankey(result?.sankey, result?.id_name_mapping),
    } as Result;
  }

  getFilters(data: Record<string, any>, fieldName: string = ''): string[] {
    let filters: string[] = [];
    data.forEach((d) => {
      let value = d;
      if (fieldName !== '') {
        value = REFERENCES[d[fieldName]] || d[fieldName];
      }
      if (!filters.includes(value)) {
        filters = filters.concat(value);
      }
    });
    return filters;
  }

  mapCurves(
    data: any,
    entities: ResultReferenceEntities,
    campaignData: ResultReferenceCampaign,
    clientData: ClientReferenceData,
  ): PortfolioCurveEntities {
    if (data) {
      return {
        [KPIType.ECONOMICS]: EconomicsCurvesMapper.mapEconomicsCurves(
          data,
          entities,
          clientData,
          campaignData,
        ),
        [KPIType.ENVIRONMENTAL]:
          EnvironmentalCurvesMapper.mapEnvironmentalCurves(
            data,
            entities,
            clientData,
            campaignData,
          ),
        [KPIType.SUSTAINABILITY]:
          SustainabilityCurvesMapper.mapSustainabilityCurves(
            data,
            entities,
            clientData,
            campaignData,
          ),
      };
    }
    return null;
  }

  mapBaselineIndicatorsToFrontend(
    data: BackendBaselineIndicators,
    output: BackendBaselineIndicatorsOutput = {
      snapshot: null,
      worldmap_kpis: null,
    },
  ): BaselineIndicators {
    return {
      id: data?.roadmapId,
      clientId: data?.clientId,
      baselineId: data?.baselineId,
      analysis: AnalysisMapper.mapToFrontend(output?.snapshot),
      worldMap: WorldmapKpisMapper.mapToFrontend(output?.worldmap_kpis),
    };
  }

  mapProjects(
    projects: BackendProjectDetails[],
    entities: ResultReferenceEntities,
    campaignData: ResultReferenceCampaign,
    clientData: ClientReferenceData,
  ): ProjectDetails[] {
    return projects.map((project: BackendProjectDetails) => {
      const campaignType =
        campaignData?.[project.campaignId]?.campaignType || '';
      const { siteId, geoId, campaignId, duration, capex, startDate } = project;
      return {
        geoId,
        siteId,
        campaignId,
        duration,
        capex,
        startDate,
        campaign: BaseGraphMapper.handleCampaignNames(
          campaignId,
          campaignType,
          '',
          isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)
            ? siteId || geoId
            : geoId,
          {
            ...entities?.existing_mapping,
            ...entities?.campaign_mapping,
          },
        ),
        campaignType,
        geography: entities?.site_mapping?.[project.geoId] || project.geoId,
        ...(isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)
          ? {
              site: clientData?.sites?.[project.siteId]?.name,
            }
          : {}),
        assetSize: project?.asset?.size,
        assetInstallationYear: new Date(startDate)?.getUTCFullYear(),
        assetExpirationYr: project?.asset?.expiration_year,
        geoHid: clientData?.geographies?.[project.geoId]?.['hId'],
        geoType: clientData?.geographies?.[project.geoId]?.['geoType'],
        endDate: this.computeProjectEndDate(startDate, duration),
      } as ProjectDetails;
    });
  }

  private computeProjectEndDate(startDate: string, duration: number) {
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + duration);
    return endDate.getFullYear().toString();
  }

  mapSankey(
    sankeyData: BackendSankeyType,
    mappers: ResultReferenceEntities,
  ): SankeyModel {
    const { site_mapping, fluid_mapping, campaign_mapping, existing_mapping } =
      mappers;
    const campaignMappers = [
      campaign_mapping,
      existing_mapping,
      MOCK_CAMPAIGN_MAPPER,
    ];
    const sankeyModel: SankeyModel = {
      years: [],
      geographies: [],
      data: {},
    };

    if (sankeyData) {
      sankeyModel.years = Coerce.getObjKeys(sankeyData);
      sankeyModel.data = Object.entries(sankeyData).reduce(
        (acc, [year, data]) => {
          acc[year] = data.map((datum) => {
            const geography = site_mapping[datum.siteId];
            const mappedDatum = {
              ...datum,
              geography,
              fluid: fluid_mapping[datum.fluidId],
              srcCampaign: this.mapCampaign(
                campaignMappers,
                datum.srcCampaignId,
              ),
              destCampaign: this.mapCampaign(
                campaignMappers,
                datum.destCampaignId,
              ),
            };

            sankeyModel.geographies.push(geography);

            return mappedDatum;
          });
          return acc;
        },
        {},
      );

      sankeyModel.geographies = [...new Set(sankeyModel.geographies)].sort();
    }

    return sankeyModel;
  }

  private mapCampaign(mappers: Record<string, string>[], id: string) {
    return mappers.find((mapper) => mapper[id])?.[id] || '';
  }

  makeGetEndpoint(
    { clientId, roadmapId }: ResultGetParameters,
    isZipped: boolean = false,
  ): string {
    const { baseUrl, endpoints } = this.config?.api;
    return generateEndpoint(
      baseUrl,
      isZipped
        ? endpoints?.portfolio?.getComputationResultsZip
        : endpoints?.portfolio?.getComputationResults,
      clientId,
      roadmapId,
    );
  }

  makeGetAllEndpoint(clientId: string): string {
    return 'assets/mocks/portfolio.mock.json';
  }

  makeGetLogEndpoint(clientId: string, id: string) {
    const { baseUrl, endpoints } = this.config?.api;
    return generateEndpoint(
      baseUrl,
      endpoints?.portfolio?.getLogDownload,
      clientId,
      id,
    );
  }

  makeGetResultExcelEndpoint(clientId: string, id: string) {
    const { baseUrl, endpoints } = this.config?.api;
    return generateEndpoint(
      baseUrl,
      endpoints?.portfolio?.getComputationResultsExcel,
      clientId,
      id,
    );
  }

  makeGetInputsEndpoint(clientId: string, id: string) {
    const { baseUrl, endpoints } = this.config?.api;
    return generateEndpoint(
      baseUrl,
      endpoints?.portfolio?.getInputsDownload,
      clientId,
      id,
    );
  }

  makeGetBaselineIndicatorsEndpoint(clientId: string, id: string) {
    const { baseUrl, endpoints } = this.config?.api;
    return generateEndpoint(
      baseUrl,
      endpoints?.portfolio?.getBaselineIndicators,
      clientId,
      id,
    );
  }

  makeGetMultipleBaselineIndicatorsEndpoint(clientId: string) {
    const { baseUrl, endpoints } = this.config?.api;
    return generateEndpoint(
      baseUrl,
      endpoints?.portfolio?.getMultipleBaselineIndicators,
      clientId,
    );
  }

  private downloadFile(response: BlobPart, filename: string): void {
    const blob = new Blob([response], {
      type: 'applications/octet-stream',
    });
    const resUrl = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = resUrl;
    anchor.click();
  }

  constructor(
    private httpClient: HttpClient,
    private config: ConfigService,
    private resultFacade: ResultFacadeService,
    private clientFacade: ClientFacadeService,
    private localStorage: ResultLocalStorageService,
  ) {}
}
