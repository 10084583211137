import { throwError } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { SignedUrl } from 'ssotool-app/app.model';
import { generateEndpoint } from 'ssotool-core/utils';
import { download, processDownloadedData } from 'ssotool-shared/services';
import { ConfigService } from 'ssotool-shared/services/config';

import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import {
  RoadmapImportFormModel,
  RoadmapImportLog,
  RoadmapImportModel,
  RoadmapImportUploadSigned,
} from './roadmap-import.model';

@Injectable()
export class RoadmapImportAPIService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  get(clientId: string, roadmapId: string, importId: string) {
    return this.http
      .get<RoadmapImportModel>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.roadmapImport.getImportRoadmapInfo,
          clientId,
          roadmapId,
          importId,
        ),
      )
      .pipe(
        map((response) => this.mapImportRoadmapToFrontend(response)),
        catchError((error) => throwError(error)),
      );
  }

  getList(clientId: string, roadmapId: string) {
    return this.http
      .get<RoadmapImportModel[]>(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.roadmapImport.getImportRoadmapList,
          clientId,
          roadmapId,
        ),
      )
      .pipe(
        map((data) =>
          data.map((datum) => this.mapImportRoadmapToFrontend(datum)),
        ),
        catchError((error) => throwError(error)),
      );
  }

  importRoadmap(
    clientId: string,
    roadmapId: string,
    importRoadmap: RoadmapImportFormModel,
  ) {
    return this.http
      .post(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.roadmapImport.importRoadmap,
          clientId,
          roadmapId,
        ),
        {
          description: importRoadmap.description,
        },
      )
      .pipe(
        mergeMap((uploadResponse: any) =>
          this.http
            .get(
              generateEndpoint(
                this.config.api.baseUrl,
                this.config.api.endpoints.roadmapImport.getImportSignedUrl,
                clientId,
                roadmapId,
                uploadResponse.importId,
                importRoadmap.file.name,
              ),
            )
            .pipe(
              mergeMap((uploadSignedUrl: RoadmapImportUploadSigned) =>
                this.http
                  .put(uploadSignedUrl.signedUrl, importRoadmap.file)
                  .pipe(
                    map(() => this.mapImportRoadmapToFrontend(uploadResponse)),
                  ),
              ),
            ),
        ),
        catchError((error) => throwError(error)),
      );
  }

  downloadRoadmapImportData(
    clientId: string,
    roadmapId: string,
    importId: string,
  ) {
    return this.http
      .get(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.roadmapImport.getDownloadSignedUrl,
          clientId,
          roadmapId,
          importId,
        ),
      )
      .pipe(
        mergeMap((downloadSignedUrl: any) =>
          this.http
            .get(downloadSignedUrl.signedUrl, {
              responseType: 'blob',
            })
            .pipe(
              map((response: any) => {
                const blob = new Blob([response], {
                  type: 'applications/octet-stream',
                });
                const resUrl = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = downloadSignedUrl.filename;
                anchor.href = resUrl;
                anchor.click();
                return response;
              }),
            ),
        ),
        catchError((error) => throwError(error)),
      );
  }

  downloadErrorLogs(clientId: string, roadmapId: string, importId: string) {
    return this.http
      .get(
        generateEndpoint(
          this.config?.api?.baseUrl,
          this.config?.api?.endpoints?.roadmapImport?.getImportRoadmapLogs,
          clientId,
          roadmapId,
          importId,
        ),
      )
      .pipe(
        filter((url) => !!url),
        mergeMap((logDownloadSignedUrl: SignedUrl) =>
          this.http
            .get(logDownloadSignedUrl.signedUrl, {
              reportProgress: true,
              observe: 'events',
              responseType: 'blob',
            })
            .pipe(
              download(),
              processDownloadedData<string>(),
              map((logs) => this.parseMessages(logs)),
            ),
        ),
      );
  }

  mapImportRoadmapToFrontend(data: RoadmapImportModel) {
    return {
      clientId: data.clientId,
      importId: data.importId,
      roadmapId: data.roadmapId,
      owner: data.owner,
      description: data.description,
      createdAt: this.formatDate(data.createdAt),
      filename: data.filename,
      status: data.status,
      logDetails: data.logDetails,
    };
  }

  parseMessages(messages: any) {
    if (messages) {
      let parsedMessages: any;
      try {
        parsedMessages = JSON.parse(messages);
        return this.groupRoadmapByModule(parsedMessages?.issues);
      } catch (error) {
        return messages;
      }
    } else {
      return messages;
    }
  }

  groupRoadmapByModule(messages: RoadmapImportLog[]) {
    const newMessages = {};
    messages.forEach((data: RoadmapImportLog) => {
      let module = data?.location?.module;
      if (module === undefined) {
        module = 'Internal Error';
      }
      newMessages[module] = newMessages[module]
        ? newMessages[module].concat(data)
        : [data];
    });
    return newMessages;
  }

  deleteImport(clientId: string, roadmapId: string, importId: string) {
    return this.http
      .delete(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.roadmapImport.delete,
          clientId,
          roadmapId,
          importId,
        ),
      )
      .pipe(catchError((error) => throwError(error)));
  }

  private formatDate(dateString: string): string {
    return formatDate(dateString, 'dd MMM YYYY HH:mm', this.locale);
  }

  downloadTemplate(clientId: string) {
    return this.http
      .get(
        generateEndpoint(
          this.config.api.baseUrl,
          this.config.api.endpoints.roadmapImport.downloadTemplate,
          clientId,
        ),
      )
      .pipe(
        mergeMap((downloadSignedUrl: SignedUrl) =>
          this.http
            .get(downloadSignedUrl.signedUrl, {
              responseType: 'blob',
            })
            .pipe(
              map((response: any) => {
                const blob = new Blob([response], {
                  type: 'applications/octet-stream',
                });
                const resUrl = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = downloadSignedUrl.filename;
                anchor.href = resUrl;
                anchor.click();
                return response;
              }),
            ),
        ),
        catchError((error) => throwError(error)),
      );
  }
}
