/* eslint-disable no-console */
export const endpoints = {
  support: {
    support: 'ssotool/support',
  },
  user: {
    getGDPRPolicy: 'user/privacy_notice',
    acceptGDPRPolicy: 'privacy_notice/update',
    getUserByEmail: 'users/filter_email/:email',
    getUserById: 'users/:id',
    searchUsersByName: 'users/search',
  },
  clients: {
    create: 'ssotool/clients',
    getClientList: 'ssotool/clients',
    getClient: 'ssotool/clients/:id',
    archiveClient: 'ssotool/clients/:id/archive',
    getUsers: 'ssotool/clients/:id/shared',
    share: 'ssotool/clients/:id/shared',
    delete: 'ssotool/clients/:id',
    update: 'ssotool/clients/:id',
    getClientData: 'ssotool/clients/:id/download',
    duplicateClient: 'ssotool/clients/:id/duplicate',
    getLogCredentials: 'ssotool/clients/:clientId/log_credentials',
  },
  dataImport: {
    importData: 'ssotool/clients/:clientId/imports',
    getDownloadSignedUrl:
      'ssotool/clients/:clientId/imports/:importId/download',
    getImportSignedUrl:
      'ssotool/clients/:clientId/imports/:importId/upload?filename=:filename',
    getImportDataInfo: 'ssotool/clients/:clientId/imports/:importId',
    deleteImport: 'ssotool/clients/:clientId/imports/:importId',
    getImportDataList: 'ssotool/clients/:clientId/imports',
    downloadTemplate: 'ssotool/clients/:clientId/template',
    downloadTemplateByType: 'ssotool/templates/:type',
    getImportLogs: 'ssotool/clients/:clientId/imports/:importId/logs',
    selectTrajectories:
      'ssotool/clients/:clientId/imports/:importId/tdb/update',
    getCommodities: 'ssotool/clients/:clientId/imports/:importId/tdb/download',
  },
  entities: {
    companies: 'ssotool/clients/:clientId/companies',
    company: 'ssotool/clients/:clientId/companies/:companyId',
    geographies: 'ssotool/clients/:clientId/geos',
    geography: 'ssotool/clients/:clientId/geos/:geoId',
    sectors: 'ssotool/clients/:clientId/sectors',
    sector: 'ssotool/clients/:clientId/sectors/:sectorId',
    fluids: 'ssotool/clients/:clientId/fluids',
    fluid: 'ssotool/clients/:clientId/fluids/:fluidId',
    processes: 'ssotool/clients/:clientId/processes',
    process: 'ssotool/clients/:clientId/processes/:processId',
    metrics: 'ssotool/clients/:clientId/metrics',
    metric: 'ssotool/clients/:clientId/metrics/:metricId',
    quantity: 'ssotool/clients/:clientId/quantities/:quantityId',
    constraints: 'ssotool/clients/:clientId/trajectories',
    trajectory: 'ssotool/clients/:clientId/trajectories/:trajectoryId',
    timeseriesList: 'ssotool/clients/:clientId/timeseries',
    timeseries: 'ssotool/clients/:clientId/timeseries/:timeseriesId',
    flowPrices: 'ssotool/clients/:clientId/flowprices',
    flowPrice: 'ssotool/clients/:clientId/flowprices/:flowPriceId',
    flowVolumes: 'ssotool/clients/:clientId/flowvolumes',
    flowVolume: 'ssotool/clients/:clientId/flowvolumes/:flowVolumeId',
    converters: 'ssotool/clients/:clientId/converters',
    converter: 'ssotool/clients/:clientId/converters/:converterId',
  },
  campaign: {
    create: 'ssotool/clients/:clientId/campaigns/:campaignType',
    getCampaigns: 'ssotool/clients/:clientId/campaigns',
    getCampaign: 'ssotool/clients/:clientId/campaigns/:campaignType/:id',
    delete: 'ssotool/clients/:clientId/campaigns/:campaignType/:id',
    update: 'ssotool/clients/:clientId/campaigns/:campaignType/:id',
    populateWithLibrary: 'ssotool/clients/:clientId/campaigns/populate',
    batchDuplicate: 'ssotool/clients/:clientId/campaigns/duplicate',
    batchDelete: 'ssotool/clients/:clientId/campaigns',
    export: 'ssotool/clients/:clientId/campaigns/export',
    cancelExport: 'ssotool/clients/:clientId/campaigns/export/:exportId/cancel',
    downloadExport:
      'ssotool/clients/:clientId/campaigns/export/:exportId/download',
    downloadTemplate: 'ssotool/clients/:clientId/campaigns/template',
  },
  binstore: {
    // load: 'ssotool/clients/:clientId/binstore/:location/:entityId/:localId',
    downloadSignedUrl: 'ssotool/clients/:clientId/binstore/:location',
  },
  libraryImport: {
    importLibrary: 'ssotool_b/libraries/imports',
    getImportLibraryList: 'ssotool_b/libraries/imports',
    getImportLibraryInfo: 'ssotool_b/libraries/imports/:importId',
    getImportSignedUrl:
      'ssotool_b/libraries/imports/:importId/upload?filename=:filename',
    getDownloadSignedUrl: 'ssotool_b/libraries/imports/:importId/download',
    getLogDownloadSignedUrl:
      'ssotool_b/libraries/imports/:importId/log_download',
  },
  portfolio: {
    roadmap: 'ssotool_b/clients/:clientId/roadmaps/:roadmapId',
    roadmaps: 'ssotool_b/clients/:clientId/roadmaps',
    createRoadmap: 'ssotool_b/clients/:clientId/roadmaps',
    computeMultipleRoadmap:
      'ssotool_b/clients/:clientId/roadmaps/compute/multiple',
    updateRoadmap: 'ssotool_b/clients/:clientId/roadmaps/:roadmapId',
    deleteRoadmap: 'ssotool_b/clients/:clientId/roadmaps/:roadmapId',
    computeRoadmap: 'ssotool_b/clients/:clientId/roadmaps/:roadmapId/compute',
    stopComputeRoadmap:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/cancel',
    getLogDownload:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/download_log',
    getLogCredentials:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/variations/:variationId/log_credentials',
    getInputsDownload:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/download_input',
    getComputationResults:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/download_output/json',
    getComputationResultsZip:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/download_output/zip',
    getBaselineIndicators:
      'ssotool/clients/:clientId/roadmaps/:roadmapId/baseline_indicators',
    getComparisonResults: 'ssotool_b/clients/:clientId/roadmaps/compare',
    getComputationResultsExcel:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/download_output/xls',
    duplicateRoadmaps: 'ssotool_b/clients/:clientId/roadmaps/duplicate',
    fetchRoadmapCampaigns:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/campaigns',
    updateRoadmapCampaigns:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/campaigns',
    deleteRoadmapCampaigns:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/campaigns',
  },
  result: {
    getList: 'ssotool_b/clients/:clientId/roadmaps/results/exports',
    createDetailedExport:
      'ssotool_b/clients/:clientId/roadmaps/results/exports',
    createSummaryExport: 'ssotool_b/clients/:clientId/roadmaps/exports/summary',
    cancelExport:
      'ssotool_b/clients/:clientId/roadmaps/results/exports/:exportId/cancel',
  },
  ambition: {
    updateTargets: 'ssotool/clients/:clientId/constraints',
    createCustomTarget: 'ssotool/clients/:clientId/targets',
    editCustomTarget: 'ssotool/clients/:clientId/targets/:constraintId',
    deleteCustomTarget: 'ssotool/clients/:clientId/targets/:constraintId',
    listConstraints: 'ssotool/clients/:clientId/constraints',
  },
  alphaRequests: {
    getList: 'alpha/request',
    updateRequest: 'alpha/request/:requestId',
  },
  campaignImport: {
    importCampaign: 'ssotool/clients/:clientId/campaigns/imports',
    getDownloadSignedUrl:
      'ssotool/clients/:clientId/campaigns/imports/:importId/download',
    getImportSignedUrl:
      'ssotool/clients/:clientId/campaigns/imports/:importId/upload?filename=:filename',
    getImportCampaignInfo:
      'ssotool/clients/:clientId/campaigns/imports/:importId',
    getImportCampaignList: 'ssotool/clients/:clientId/campaigns/imports',
    getImportCampaignLogs:
      'ssotool/clients/:clientId/campaigns/imports/:importId/logs',
    delete: 'ssotool/clients/:clientId/campaigns/imports/:importId',
  },
  roadmapImport: {
    importRoadmap: 'ssotool_b/clients/:clientId/roadmaps/:roadmapId/imports',
    getDownloadSignedUrl:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/imports/:importId/download',
    getImportSignedUrl:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/imports/:importId/upload?filename=:filename',
    getImportRoadmapInfo:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/imports/:importId',
    getImportRoadmapList:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/imports',
    getImportRoadmapLogs:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/imports/:importId/logs',
    delete: 'ssotool_b/clients/:clientId/roadmaps/:roadmapId/imports/:importId',
    downloadTemplate: 'ssotool_b/clients/:clientId/roadmaps/template',
  },
  notifications: {
    getList: 'ssotool/notifications',
    read: 'ssotool/notifications',
  },
  announcements: {
    getList: 'announcements',
    read: 'announcements/:announcementId/read',
  },
  releaseNotes: {
    get: 'ssotool/release_notes/download',
    put: 'ssotool/release_notes',
  },
  profiles: {
    getSiteProfile: 'ssotool/clients/:clientId/profiles/:profileId/geos/:geoId',
    upsert: 'ssotool/clients/:clientId/profiles/:profileId/geos/:geoId',
    delete: 'ssotool/clients/:clientId/profiles/:profileId/geos/:geoId',
    download: 'ssotool/clients/:clientId/campaigns/download',
    update: 'ssotool/clients/:clientId/campaigns/:campaignType/:id',
  },
  fluidDemand: {
    get: 'ssotool_b/clients/:clientId/demand/fluid',
  },
  roadmapVariation: {
    get: 'ssotool_b/clients/:clientId/roadmaps/:roadmapId/variations/:variationId',
    list: 'ssotool_b/clients/:clientId/roadmaps/:roadmapId/variations',
  },
  roadmapVisualization: {
    get: 'ssotool_b/clients/:clientId/roadmaps/:roadmapId/visualizations/:visualizationId',
    save: 'ssotool_b/clients/:clientId/roadmaps/:roadmapId/visualizations',
    list: 'ssotool_b/clients/:clientId/roadmaps/:roadmapId/visualizations',
    delete:
      'ssotool_b/clients/:clientId/roadmaps/:roadmapId/visualizations/:visualizationId',
  },
  inputConverter: {
    getImportSignedURL: 'ssotool_b/clients/:clientId/input_converter',
  },
};

let _version: string;
try {
  _version = require('./environment-version') || undefined;
} catch (e) {
  // NOTES: Disabled warning intentionally.
  // console.warn(
  //   'No environment-version.ts found! Please run `npm run version:ssotool`',
  // );
  _version = undefined;
}

export const version = _version;

export const OKTA_CALLBACK_PATH = 'login/callback';

export const SSOToolOkta = {
  issuer: 'https://et-tractebel.oktapreview.com/oauth2/default',
  redirectUri: window.location.origin + '/' + OKTA_CALLBACK_PATH,
};
