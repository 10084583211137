import { catchError, mergeMap, Observable, of } from 'rxjs';
import { AuthService } from 'ssotool-app/shared/services/auth/auth.service';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.authService.selectIsAuthenticated().pipe(
      mergeMap((isAuthenticated) => {
        if (!!isAuthenticated) {
          return this.redirectToClients();
        }
        return of(true);
      }),
      catchError(() => of(true)),
    );
  }

  protected redirectToClients(): Observable<boolean> {
    this.router.navigate(['/clients']);
    return of(false);
  }
}
